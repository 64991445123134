.tpm_table {
    background-color: white;
    
    border-radius: 15px;
}

.icon {
    width: 20px;
    height: 20px;
}

.first-layer-header {
    background-color: hsl(41 80% 80%) !important;
}

.second-layer-header {
    background-color: hsl(41 80% 90%) !important;
}

.third-layer-header {
    background-color: hsl(41 80% 94%) !important;
}

.gray {
    background-color: lightgray !important;
}

.svg-inline--fa {
    background-color: unset !important;
}

.light-shadow {
    box-shadow: 1px 1px 2px;
    margin-bottom: 3px;
}