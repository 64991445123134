.header-opacity {
    color: #0009;
}

.light-text {
    color: #0006;
}

.bold {
    font-weight: bold;
}