.parentDiv{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    overflow-y: scroll;

}
/* .childDiv{
    position: absolute;
    top: 0;
    left: 0;
    bottom: -20px;
    right: -20px; 
    overflow: scroll;
 } */

 .h-80{
    height: 80%;
 }
 .w-70{
    width: 70%;
 }
 .space{
    margin-left: 2px;
    margin-right: 2px;
 }

 .active-btn{
    background-color: #565e50;
 }

 .hidden{
   visibility: hidden
 }