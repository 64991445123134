
.row-header {
  margin: 0px auto;
  padding: 0px auto;
}

.row-content {
  margin: 0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}

.footer {
  background-color: #D1C4E9;
  margin: 0px auto;
  padding: 20px 0px 20px 0px;
}

.jumbotron {
  padding: 70px 30px 70px 30px;
  margin: 0px auto;
  background: #9575CD;
  color: floralwhite;
}

.address {
  font-size: 80%;
  margin: 0px;
  color: #0f0f0f;
}

.page-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.fc .fc-datagrid-cell-cushion {
  white-space: unset !important;
  overflow: hidden;
}

.fc-datagrid-cell .fc-resources{
  font-size: small !important;
}



.fc-datagrid-cell-main,
.fc-datagrid-cell-cushion {
  font-size: small !important;  
}

/* .fc-datagrid-cell-cushion :nth-child(3) {
  font-size: medium !important;  
  font-weight: 900;
  writing-mode: vertical-rl;
} */

/* .fc-datagrid-cell-cushion :nth-child(2) {
  font-size: medium !important;  
  font-weight: bold;
} */

/* .bold > div :nth-child(1) {
  font-weight: bold;
  font-size: medium;
}  */

.customers-text > div > div > span    {
  font-weight: bold;
  font-size: large !important;
} 


.rotate-90 > div > div > span {  
  font-weight: bold;  
  font-size: medium !important;
  writing-mode:vertical-rl;
}

.prom{
  background-color: #ffc64e;
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
}
.center{
  display: flex;
  justify-content: center;
}

.with-actual{
  border:  3px solid #0f0f0f !important;
}

.approval-selector{
  height: 35px;
  margin-bottom: 8px;
  margin-top: 4px;
  padding: 2px;
}



.promotion-list-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fc-event-time,
.fc-event-title {
  padding: 0 1px;
  white-space: normal !important;
}

div.react-confirm-alert-overlay {
  z-index: 9999;
}

.selected-3 {
    border: 3px solid darkgreen;
}

.selected-2 {
    border: 3px solid sienna;
}

.status-card-subtitle {
  color: white !important;
}

.button-user {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;

}

/* .button-logout {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

} */

.medium-border {
    border-width: 3px !important;
}

.bg-lvl-1 {
    background-color: var(--bg-body-color-lvl-1);
}

.bg-lvl-2 {
    background-color: var(--bg-body-color-lvl-2);
}

.bg-lvl-3 {
    background-color: var(--bg-body-color-lvl-3);
}

.bg-login-lvl-3 {
    background-color: var(--bg-login-color-lvl-1);
}

.row-shadow {
    box-shadow: 0px 3px 4px #888;
}

.toolbar-shadow {
    box-shadow: 0 3px 4px #888;
}

.element-shadow {
    box-shadow: 3px 3px 4px #888;
}

.fa-1x-spaced {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
}

.fa-1_6x-spaced {
    font-size: 1.6em;
}

.highlight {
    border: 3px solid rgba(200, 200, 200, .4) !important;
}

.z-1 {
    z-index: 1;
}

.color-black {
    color: black;
}

.fc-timeline-slot-frame > a {
    text-decoration: none;
}

.justify-cell-end > div {
    display: flex;
    justify-content: flex-end;
}

.text-align-end {
  text-align: end;
}

.text-align-center {
  text-align: center;
}

.text-align-start {
  text-align: start;
}

.w-60 {
  width: 60% !important;
}

.input-group-text {
  background-color: var(--input-group-text-bg-color);
  border-color: var(--input-group-text-border-color);
}

.dropdown-icon {
  width: 25px;
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
