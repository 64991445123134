.row_entry {
    background: white;
    padding: 10px;
    border-collapse: separate;
    border-spacing: 0 1em;
    border-radius: 25px;
    margin: 20px;
}

.bold-text{
    font-weight: bold;
}
.bold-text-deleted{
    color:black;
    font-weight: bold;
    text-decoration: line-through red 2px solid ;
}

.order-input{
    width: 55px;
    height: 35px;
}

