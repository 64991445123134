:root{
  --bg-promotion-deleted: #ff0000;
  --bg-promotion-draft: #bcbcbc;
  --bg-promotion-rejected: #ff5500;
  --bg-promotion-pending-approval: #f3b940;
  --bg-promotion-approved: #33d693;
  --bg-promotion-po-submitted: #0070c0;
  --bg-promotion-po-finalized: #29d10e;
  --bg-promotion-completed: #148c01;

  --fc-bg-label-days: #0000;
  --fc-color-label-color: black;
  --fc-bg-label-unavailable-days: #b0b0b033;
  --fc-color-label-unavailable-color: #b9b9b9;

  --fc-bg-lane-days: #0000;
  --fc-color-lane-color: black;
  --fc-bg-lane-unavailable-days: #b0b0b033;
  --fc-color-lane-unavailable-color: #b9b9b9;

  --fc-button-bg-color: #dadada;
  --fc-button-border-color: #ffffff;
  --fc-button-text-color: black;
  --fc-button-hover-bg-color: #ffd783;
  --fc-button-hover-border-color: #ffffff;
  --fc-button-hover-text-color: black;
  --fc-button-active-bg-color: #ffd783;
  --fc-button-active-border-color: #ffffff;
  --fc-button-active-text-color: black;

  --fc-lane-color-odd-line: #c0c0c033;
  --fc-lane-color-even-line: #f5f5f533;

  --bg-resource-total-euro: #ffb824;
  --color-resource-total-euro: #000;
  --bg-resource-media-fee: #ffc64e;
  --color-resource-media-fee: #000;
  --bg-resource-discount-euro: #ffd783;
  --color-resource-discount-euro: #333;
  --bg-resource-total-hls: #ffedbb;
  --color-resource-total-hls: #333;
/*  --bg-resource-media-fee: #cc8a00;*/
/*  --bg-resource-total-euro: #e29700;*/
/*  --bg-resource-total-hls: #ee9f00;*/

  --bg-login-color-lvl-1: #f5f5f5;
  --bg-body-color-lvl-1: #f5f5f5;
  --bg-body-color-lvl-2: #f9f9f9;
  --bg-body-color-lvl-3: #fff;

  --input-group-text-bg-color: #f5f5f5;
  --input-group-text-border-color: #e8e8e8;

  --navbar-bg-color: #ffd57b;

  --success-bs-btn-bg: #189400;
  --success-bs-btn-color: #fff;
  --success-bs-btn-border-color: #198754;
  --success-bs-btn-hover-color: #fff;
  --success-bs-btn-hover-bg: #157347;
  --success-bs-btn-hover-border-color: #146c43;
  --success-bs-btn-focus-shadow-rgb: 60,153,110;
  --success-bs-btn-active-color: #fff;
  --success-bs-btn-active-bg: #146c43;
  --success-bs-btn-active-border-color: #13653f;
  --success-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --success-bs-btn-disabled-color: #fff;
  --success-bs-btn-disabled-bg: #198754;
  --success-bs-btn-disabled-border-color: #198754;

  --danger-bs-btn-color: #fff;
  --danger-bs-btn-bg: #dc3545;
  --danger-bs-btn-border-color: #dc3545;
  --danger-bs-btn-hover-color: #fff;
  --danger-bs-btn-hover-bg: #bb2d3b;
  --danger-bs-btn-hover-border-color: #b02a37;
  --danger-bs-btn-focus-shadow-rgb: 225,83,97;
  --danger-bs-btn-active-color: #fff;
  --danger-bs-btn-active-bg: #b02a37;
  --danger-bs-btn-active-border-color: #a52834;
  --danger-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --danger-bs-btn-disabled-color: #fff;
  --danger-bs-btn-disabled-bg: #dc3545;
  --danger-bs-btn-disabled-border-color: #dc3545;

  --primary-bs-btn-color: #fff;
  --primary-bs-btn-bg: #cc8a00;
  --primary-bs-btn-border-color: #c38200;
  --primary-bs-btn-hover-color: #fff;
  --primary-bs-btn-hover-bg: #c38200;
  --primary-bs-btn-hover-border-color: #c38200;
  --primary-bs-btn-focus-shadow-rgb: 49,132,253;
  --primary-bs-btn-active-color: #fff;
  --primary-bs-btn-active-bg: #e09900;
  --primary-bs-btn-active-border-color: #e09900;
  --primary-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --primary-bs-btn-disabled-color: #fff;
  --primary-bs-btn-disabled-bg: #ffb411;
  --primary-bs-btn-disabled-border-color: #ffb310;
}

.btn-success{
  --bs-btn-bg: var(--success-bs-btn-bg);
  --bs-btn-color: var(--success-bs-btn-color);
  --bs-btn-border-color: var(--success-bs-btn-border-color);
  --bs-btn-hover-color: var(--success-bs-btn-hover-color);
  --bs-btn-hover-bg: var(--success-bs-btn-hover-bg);
  --bs-btn-hover-border-color: var(--success-bs-btn-hover-border-color);
  --bs-btn-focus-shadow-rgb: var(--success-bs-btn-focus-shadow-rgb);
  --bs-btn-active-color: var(--success-bs-btn-active-color);
  --bs-btn-active-bg: var(--success-bs-btn-active-bg);
  --bs-btn-active-border-color: var(--success-bs-btn-active-border-color);
  --bs-btn-active-shadow: var(--success-bs-btn-active-shadow);
  --bs-btn-disabled-color: var(--success-bs-btn-disabled-color);
  --bs-btn-disabled-bg: var(--success-bs-btn-disabled-bg);
  --bs-btn-disabled-border-color: var(--success-bs-btn-disabled-border-color);
}

.btn-danger {
  --bs-btn-color: var(--danger-bs-btn-color);
  --bs-btn-bg: var(--danger-bs-btn-bg);
  --bs-btn-border-color: var(--danger-bs-btn-border-color);
  --bs-btn-hover-color: var(--danger-bs-btn-hover-color);
  --bs-btn-hover-bg: var(--danger-bs-btn-hover-bg);
  --bs-btn-hover-border-color: var(--danger-bs-btn-hover-border-color);
  --bs-btn-focus-shadow-rgb:var(--danger-bs-btn-focus-shadow-rgb);
  --bs-btn-active-color: var(--danger-bs-btn-active-color);
  --bs-btn-active-bg: var(--danger-bs-btn-active-bg);
  --bs-btn-active-border-color: var(--danger-bs-btn-active-border-color);
  --bs-btn-active-shadow:var(--danger-bs-btn-active-shadow);
  --bs-btn-disabled-color: var(--danger-bs-btn-disabled-color);
  --bs-btn-disabled-bg: var(--danger-bs-btn-disabled-bg);
  --bs-btn-disabled-border-color: var(--danger-bs-btn-disabled-border-color);
}

.btn-primary {
  --bs-btn-color:  var(--primary-bs-btn-color);
  --bs-btn-bg:  var(--primary-bs-btn-bg);
  --bs-btn-border-color:  var(--primary-bs-btn-border-color);
  --bs-btn-hover-color:  var(--primary-bs-btn-hover-color);
  --bs-btn-hover-bg:  var(--primary-bs-btn-hover-bg);
  --bs-btn-hover-border-color:  var(--primary-bs-btn-hover-border-color);
  --bs-btn-focus-shadow-rgb: var(--primary-bs-btn-focus-shadow-rgb);
  --bs-btn-active-color:  var(--primary-bs-btn-active-color);
  --bs-btn-active-bg:  var(--primary-bs-btn-active-bg);
  --bs-btn-active-border-color:  var(--primary-bs-btn-active-border-color);
  --bs-btn-active-shadow: var(--primary-bs-btn-active-shadow);
  --bs-btn-disabled-color:  var(--primary-bs-btn-disabled-color);
  --bs-btn-disabled-bg:  var(--primary-bs-btn-disabled-bg);
  --bs-btn-disabled-border-color:  var(--primary-bs-btn-disabled-border-color);
}

.color-navbar {
  background-color: var(--navbar-bg-color);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
